import React from "react"
import Layout from "../components/Layout"

export default function about() {
  return (
    <Layout>
      <div>
        <h1>About Page</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus, quis.
          Ullam mollitia officiis consequuntur quas iste ipsum labore laborum
          nihil perferendis esse distinctio, sed aspernatur praesentium cumque
          quibusdam asperiores facere. Commodi, dolorum? Odit itaque facilis
          natus id aut? Praesentium eligendi, quaerat cumque id numquam nam ut
          porro! Culpa, iusto sequi. Ut itaque fugiat quia? Cumque nobis rerum
          consequuntur incidunt molestiae? Recusandae nemo magni cumque neque
          praesentium ullam ab odit distinctio excepturi ex quisquam ipsam
          harum, culpa possimus ducimus reprehenderit accusantium optio nostrum
          veritatis dignissimos eum reiciendis? Id eos quia et? Ipsa doloremque
          excepturi, libero amet qui error molestiae nostrum officiis, optio
          aspernatur numquam repellat esse, magnam quibusdam vitae blanditiis
          sapiente ut consectetur pariatur natus doloribus vero accusamus ipsum
          laborum? Dicta. Sapiente esse provident eaque necessitatibus odio
          repellat, molestiae officiis voluptatibus. Accusamus possimus a in
          quia dolorem quis quo exercitationem omnis eos iusto atque veniam,
          praesentium deleniti cupiditate animi? Dolores, ipsam!
        </p>
      </div>
    </Layout>
  )
}
